import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import Seo from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"

const BlogPostTemplate = ({ data }) => {
  const post = data.thePost
  const featuredImage = {
    data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || ``,
  }

  return (
    <><Header /><Seo title={post.title} description={post.excerpt} /><article
      className="blog-post"
      itemScope
      itemType="http://schema.org/Article"
    >
      <header className="section">
        <h1 itemProp="headline" className="is-size-2">{parse(post.title)}</h1>

        {/* if we have a featured image for this post let's display it */}
        {featuredImage?.data && (
          <GatsbyImage
            image={featuredImage.data}
            alt={featuredImage.alt}
            style={{ marginBottom: 50 }} />
        )}
      </header>

      {!!post.content && (
        <section itemProp="articleBody" className=" section content">{parse(post.content)}</section>
      )}

      <hr />

    </article>
    <Footer /></>

  )
}

export default BlogPostTemplate

export const query = graphql`
  query($databaseId: Int!) {
    thePost: wpPost(databaseId: { eq: $databaseId }) {
      date
      databaseId
      content
      title
      author {
        node {
          name
        }
      }
      categories {
        nodes {
          link
          name
        }
      }
      tags {
        nodes {
          link
          name
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1360) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
